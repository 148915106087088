import { createRouter, createWebHistory } from "vue-router";
import { autodetectCountryCode } from "@/services/translation";
import { Layout } from "@/router/routeMeta";
import { AppRouteName, appRoutes, errorPagesRoutes, ErrorRouteName, LockingStateRouteName, lockingStateRoutes, PublicRouteName, publicRoutes } from "@/router/routes";
import { checkVersion, handleAccountCompletionNeeded, handleInvalidTokenFound, handleLogin, handleRedirectForLoggedInUser, localizeCookieConsent, setBreadcrumbs, setLinkAlternate, setLinkCanonical, setTitle, setUserPreferredLocalization, storeLocalizationInApp } from "@/router/guards";

export const RouteName = { ...PublicRouteName, ...AppRouteName, ...LockingStateRouteName, ...ErrorRouteName };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: () => {
        const countryCode = autodetectCountryCode();
        return "/" + countryCode;
      },
    },
    {
      path: "/facebook-sso-callback",
      components: { mainContent: () => import("@/views/FacebookSsoCallback.vue") },
      name: LockingStateRouteName.facebookSsoCallback,
      meta: {
        public: true,
        layout: Layout.LockingState,
        allowNoCountryCode: true,
      },
    },
    {
      path: "/ai-identification",
      redirect: "/cz/ai-identification",
    },
    {
      path: "/ai-identification/category/lego-sets",
      redirect: "/cz/ai-identification/category/lego-sets",
    },
    {
      path: "/ai-identification/category/sports-cards",
      redirect: "/cz/ai-identification/category/sports-cards",
    },
    {
      path: "/:countryCode(cz|sk)",
      children: [...publicRoutes, ...appRoutes, ...lockingStateRoutes, ...errorPagesRoutes],
    },
  ],
  scrollBehavior(to, from) {
    if (to.hash && document.getElementById(to.hash.slice(1))) {
      return { el: to.hash };
    }

    if (to.name === from.name && to.meta.preventScrollToTop === true) {
      return;
    }

    return { top: 0 };
  },
});

router.beforeEach(checkVersion);
router.beforeEach(storeLocalizationInApp);
router.beforeEach(handleInvalidTokenFound);
router.beforeEach(handleAccountCompletionNeeded);
router.beforeEach(setTitle);
router.beforeEach(setBreadcrumbs);
router.beforeEach(setUserPreferredLocalization);
router.beforeEach(handleLogin);
router.beforeEach(handleRedirectForLoggedInUser);

router.afterEach(setLinkCanonical);
router.afterEach(setLinkAlternate);
router.afterEach(localizeCookieConsent);

export { router };
