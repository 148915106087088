// Basic imports
import { createApp } from "vue";
import TheApp from "@/TheApp.vue";
import { router } from "@/router";
import { store } from "@/store";

// Tooltips
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import "@/scss/properties.scss";
import "@/scss/general/general.scss";

// Element plus
import { ElLoading } from "element-plus";
import "element-plus/es/components/loading/style/css";

// 3rd party logins
import vue3GoogleLogin from "vue3-google-login";

// Custom events
import mitt from "mitt";

// Onscroll animation
import "aos/dist/aos.css";

// Cookies consent
import { CookieConsentPlugin } from "@/services/cookiesConsent";

// Translations
import { gettext, i18n } from "@/services/translation";

// Toasts
import { toasts } from "@/services/toasts";

// Sentry
import { setupSentry } from "@/services/sentry";

// HTML <head> tag
import { createHead } from "@vueuse/head";

window.eventBus = mitt();

window.appVersion = import.meta.env.VITE_APP_VERSION; // Sentry (in GTM) uses this information.

const app = createApp(TheApp);
setupSentry(app);
app.use(gettext);
app.use(createHead());
app.use(vue3GoogleLogin, { clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID });
app.use(router);
app.use(FloatingVue);
app.use(i18n);
app.use(store);
app.use(CookieConsentPlugin);
app.use(toasts);

app.directive("loading", ElLoading.directive);

app.mount("#app");
