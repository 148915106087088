import { HistoryItemDetail, HistoryListItem } from "@/composables/ai";
import { CurrencyId } from "@/services/currencies";
import { factoryImage, Image } from "@/services/images";
import { IndexedDb } from "@/services/indexedDb";
import { isNumericPrice } from "@/services/prices";
import { Parameter } from "@/services/repositories/collection/categories/parameters";
import { CategoryId } from "@/types";

export const getCoverImage = (item: HistoryListItem): Image | undefined => {
  const img = item.images.length > 0 ? item.images[0] : undefined;
  if (img) {
    return factoryImage(0 /* fake ID */, img.imageUrl, item.name);
  } else {
    return undefined;
  }
};

export const getImages = (item: HistoryListItem | HistoryItemDetail): Image[] => item.images.map(({ imageUrl }) => factoryImage(0 /* fake ID */, imageUrl, item.name));

export const getParamValue = (param: Parameter, item: HistoryItemDetail): string | undefined => item.parameters.find((p) => p.parameterId === param.id)?.value;

export const hasPrice = (item: HistoryListItem | HistoryItemDetail): boolean => isNumericPrice(item.priceFrom) && isNumericPrice(item.priceTo);

type PriceRange = { from: number; to: number; currencyId: CurrencyId };
export const getPriceRange = (item: HistoryListItem | HistoryItemDetail): PriceRange => {
  if (!isNumericPrice(item.priceFrom) || !isNumericPrice(item.priceTo)) {
    throw new Error("DEV: Should not happen. Cannot get price range for item without price.");
  }
  if (item.priceFrom.currencyId !== item.priceTo.currencyId) {
    throw new Error("DEV: Should not happen. Price range currencyId mismatch.");
  }

  return {
    from: item.priceFrom.price,
    to: item.priceTo.price,
    currencyId: item.priceFrom.currencyId,
  };
};

const IndexedDbKey = "ai-vision-last-category-id";
export const saveLastCategoryId = async (id: CategoryId) => IndexedDb.set(IndexedDbKey, id);
export const getLastCategoryId = async (): Promise<CategoryId | undefined> => Number(await IndexedDb.get(IndexedDbKey));

export const FormAnchor = "AiForm";