import { useGettext } from "vue3-gettext";

export type PatronData = {
  name: string;
  nickname: string;
  image: string;
  label: string;
  description: string;
};

export enum Patron {
  Benda,
  Saviory,
  HonzovyKostky,
  Investyr,
  MikeLacroix,
  VlastimilSprta,
  PlanetaHer,
  Gramodesky,
}

export function usePatrons() {
  const { $gettext } = useGettext();

  const patrons: Record<Patron, PatronData> = {
    [Patron.Benda]: {
      name: "Karlos Benda",
      nickname: "KarlosBenda",
      image: "/images/patrons/benda.png",
      label: $gettext("Colectio Patron"),
      description: $gettext("Karlos Benda je český bojovník MMA, který soutěží v organizaci Clash of the Stars, influencer a zároveň vášnivý sběratel především popkulturních předmětů. Inspirujte se na jeho profilu."),
    },
    [Patron.Saviory]: {
      name: "Michal Saviory",
      nickname: "MichalSaviory",
      image: "/images/patrons/saviory.png",
      label: $gettext("Colectio Patron LEGO®"),
      description: $gettext("Michal Saviory je významný sběratel a prodejce LEGA®, CEO ve společnosti Saviory Bricks a LEGO® youtuber. Zabývá se prodejem jednotlivých LEGO® dílků a figurek do celého světa."),
    },
    [Patron.HonzovyKostky]: {
      name: "Honzovy Kostky",
      nickname: "Honzovy_Kostky",
      image: "/images/patrons/honzovyKostky.png",
      label: $gettext("Colectio Patron LEGO®"),
      description: $gettext("Honza Pešl je youtuber a LEGO® nadšenec, který stojí za kanálem Honzovy kostky, které se věnují novinkám, recenzím a streamování stavění, převážně LEGO® STAR WARS."),
    },
    [Patron.Investyr]: {
      name: "Investýř",
      nickname: "Investyr",
      image: "/images/patrons/investyr.png",
      label: $gettext("Colectio Patron"),
      description: $gettext("Tomáš Zahradník se specializuje na investice do sběratelských předmětů všeho druhu. Pomáhá lidem s investicemi do sběratelství skrz svoje sociální sítě."),
    },
    [Patron.MikeLacroix]: {
      name: "MikeLacroix",
      nickname: "MikeLacroix",
      image: "/images/patrons/mikelacroix.png",
      label: $gettext("Colectio Patron kartiček"),
      description: $gettext("Filip Šimanovský je vášnivý sběratel hokejových kartiček, především Connora McDavida. Je odborníkem na svět sportovních karet, fanouškem NHL a hokeje obecně."),
    },
    [Patron.VlastimilSprta]: {
      name: "Vlastimil Šprta",
      nickname: "BlairRix",
      image: "/images/patrons/vlastimilSprta.png",
      label: $gettext("Colectio Patron"),
      description: $gettext("Vlastimil Šprta je vášnivý sběratel s širokým zaměřením. Jeho sbírky obsahují celou řadu popkulturních unikátů, ale nejvíce ho fascinuje sbírání autogramů v různých formách. Kromě toho se aktivně věnuje také cosplayi, kde rád oživuje své oblíbené postavy."),
    },
    [Patron.PlanetaHer]: {
      name: "Planeta Her",
      nickname: "PlanetaHer",
      image: "/images/patrons/planetaHer.png",
      label: $gettext("Colectio Patron deskových her"),
      description: $gettext("Planetaher.cz je renomovaný český e-shop, který se specializuje na prodej her, sběratelských předmětů a všech věcí spojených s popkulturou. Srdcem jejich nabídky jsou nejen deskové a karetní hry, ale i unikátní sběratelské kousky, autogramy, figurky a další zajímavé předměty."),
    },
    [Patron.Gramodesky]: {
      name: "Gramodesky",
      nickname: "Ondra-z-gramodesek",
      image: "/images/patrons/ondraZGramodesek.png",
      label: $gettext("Colectio Patron vinylových desek"),
      description: $gettext("Gramodesky.cz je eshop specializovaný na prodej vinylových desek. Nabízí široký výběr hudebních titulů pro všechny milovníky hudby a sběratele napříč různými žánry, od klasiky po současné hity, a také na sběratelské edice a limitované tituly."),
    },
  };

  const get = (patron: Patron) => patrons[patron];

  return {
    patrons,
    get,
  };
}
